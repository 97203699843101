import { setUserId } from "firebase/analytics";
import React, { useContext, useEffect } from "react";
import { analytics } from "../firebase";

const AnalyticsContext = React.createContext();

export function useAnalytics() {
  return useContext(AnalyticsContext);
}

export function AnalyticsProvider({ children }) {
  const setUser = (userId) => {
    setUserId(analytics, userId);
  };

  const value = {
    setUser,
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
}
