import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "./authContext";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { Box, CircularProgress } from "@mui/material";
import { useAnalytics } from "./analyticsContext";
import dayjs from "dayjs";

const FirestoreContext = React.createContext();

export function useFirestore() {
  return useContext(FirestoreContext);
}

export function FirestoreProvider({ children }) {
  // State
  const [loading, setLoading] = useState(true);
  const [firestoreUser, setFirestoreUser] = useState(null);
  const [nextFixtures, setNextFixtures] = useState([]);
  const [lastFixtures, setLastFixtures] = useState([]);
  const [liveScores, setLiveScores] = useState([]);

  // Contexts
  const { currentUser } = useAuth();
  const { setUser } = useAnalytics();

  //Check for user in Firestore at start
  useEffect(() => {
    if (currentUser) {
      setUser(currentUser.uid);
      // If user is logged in
      setLoading(true);
      (async () => {
        const user = await getDoc(doc(firestore, "users", currentUser.uid));
        if (!user.exists()) {
          //When user sign up for first time and no data in firestore
          await addUser(currentUser);
          setLoading(false);
        } else {
          //When user is data is already in firestore
          getUser(currentUser);
        }
      })();
    } else {
      //Calls only if user is not logged in
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  //Function to add user data to Firestore
  async function addUser(user) {
    const userObj = {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      phoneNumber: user.phoneNumber,
      theme: "dark",
      role: "member",
      fLeagues: {},
    };

    try {
      await setDoc(doc(firestore, "users", user.uid), userObj);
      setUser(user.uid);
      setFirestoreUser(userObj);
      console.log("Document written with ID: ", user.uid);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }

  // Function to get signed in user data from Firestore
  function getUser(user) {
    // const unsub =
    onSnapshot(doc(firestore, "users", user.uid), async (doc) => {
      const data = doc.data();

      if (data.photoURL === user.photoURL) {
        setFirestoreUser(data);
        setLoading(false);
      } else {
        await addUser(currentUser);
        setLoading(false);
      }
    });

    return;
  }

  async function updateUser(data) {
    await updateDoc(doc(firestore, "users", currentUser.uid), { ...data });
  }

  async function getAllCountries() {
    const countries = [];
    const querySnapshot = await getDocs(collection(firestore, "countries"));
    querySnapshot.forEach((doc) => {
      countries.push(doc.data());
    });
    return countries;
  }

  async function getLeaguesByCountry(country) {
    const leagues = [];
    const q = query(
      collection(firestore, "leagues"),
      where("country.name", "==", country)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      leagues.push(doc.data());
    });
    return leagues;
  }

  function listenToNextFixtures() {
    let q;
    const userFavoriteLeagues = firestoreUser ? firestoreUser.fLeagues : [];
    if (userFavoriteLeagues && Object.keys(userFavoriteLeagues).length > 0) {
      // If user has favorite leagues, construct query to fetch fixtures for those leagues
      const leagueIds = Object.keys(userFavoriteLeagues).map((id) =>
        parseInt(id)
      );
      q = query(
        collection(firestore, "fixtures"),
        where("league.id", "in", leagueIds),
        where("status", "==", "next"),
        // where("fixture.timestamp", ">", dayjs().unix()),
        orderBy("fixture.timestamp", "desc"),
        limit(10)
      );
    } else {
      // If user has no favorite leagues, fetch fixtures normally without filtering
      q = query(
        collection(firestore, "fixtures"),
        where("status", "==", "next"),
        // where("fixture.timestamp", ">", dayjs().unix()),
        orderBy("fixture.timestamp", "desc"),
        limit(10)
      );
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fixtures = [];
      querySnapshot.forEach((doc) => {
        fixtures.push(doc.data());
      });
      setNextFixtures(fixtures);
    });

    return unsubscribe;
  }

  function listenToLastFixtures() {
    let q;
    const userFavoriteLeagues = firestoreUser ? firestoreUser.fLeagues : [];
    if (userFavoriteLeagues && Object.keys(userFavoriteLeagues).length > 0) {
      // If user has favorite leagues, construct query to fetch fixtures for those leagues
      const leagueIds = Object.keys(userFavoriteLeagues).map((id) =>
        parseInt(id)
      );
      q = query(
        collection(firestore, "fixtures"),
        where("status", "==", "last"),
        where("league.id", "in", leagueIds),
        // where("fixture.timestamp", "<", dayjs().unix()),
        orderBy("fixture.timestamp", "desc"),
        limit(10)
      );
    } else {
      // If user has no favorite leagues, fetch fixtures normally without filtering
      q = query(
        collection(firestore, "fixtures"),
        where("status", "==", "last"),
        // where("fixture.timestamp", "<", dayjs().unix()),
        orderBy("fixture.timestamp", "desc"),
        limit(10)
      );
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fixtures = [];
      querySnapshot.forEach((doc) => {
        fixtures.push(doc.data());
      });
      setLastFixtures(fixtures);
    });

    return unsubscribe;
  }

  function listenToLiveFixtures() {
    let q;
    const userFavoriteLeagues = firestoreUser ? firestoreUser.fLeagues : [];
    if (userFavoriteLeagues && Object.keys(userFavoriteLeagues).length > 0) {
      // If user has favorite leagues, construct query to fetch fixtures for those leagues
      const leagueIds = Object.keys(userFavoriteLeagues).map((id) =>
        parseInt(id)
      );
      q = query(
        collection(firestore, "fixtures"),
        where("status", "==", "live"),
        where("league.id", "in", leagueIds),
        orderBy("fixture.timestamp", "asc")
      );
    } else {
      // If user has no favorite leagues, fetch fixtures normally without filtering
      q = query(
        collection(firestore, "fixtures"),
        where("status", "==", "live"),
        orderBy("fixture.timestamp", "asc")
      );
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fixtures = [];
      querySnapshot.forEach((doc) => {
        fixtures.push(doc.data());
      });

      setLiveScores(fixtures);
    });

    return unsubscribe;
  }

  const value = {
    firestoreUser,
    updateUser,
    getAllCountries,
    getLeaguesByCountry,
    listenToNextFixtures,
    nextFixtures,
    listenToLastFixtures,
    lastFixtures,
    listenToLiveFixtures,
    liveScores,
  };

  return (
    <FirestoreContext.Provider value={value}>
      {!loading ? (
        children
      ) : (
        <Box
          sx={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </FirestoreContext.Provider>
  );
}
