import React from "react";
import { Box, Typography, Avatar, Grid, Stack, Divider } from "@mui/material";

export const LineupGrid = ({ lineup }) => {
  const rows1 = lineup[0].formation
    ? lineup[0].formation.split("-").length + 1
    : 0;
  const rows2 = lineup[1].formation
    ? lineup[1].formation.split("-").length + 1
    : 0;
  const formation1 = lineup[0].formation
    ? lineup[0].formation.split("-")
    : null;
  const formation2 = lineup[1].formation
    ? lineup[1].formation.split("-").reverse()
    : null;

  return (
    <Box>
      {formation1 && formation2 && (
        <Stack spacing={7}>
          {/* 1st Team */}
          {Array.from({ length: rows1 }).map((_, rowIndex) => (
            <Grid container key={rowIndex}>
              {rowIndex === 0 ? (
                <Grid item xs={12}>
                  {lineup[0].startXI.find(
                    (player) => player.player.grid === `${rowIndex + 1}:1`
                  ) ? (
                    <Box sx={{ position: "relative", mt: 2 }}>
                      <Box
                        sx={{
                          position: "absolute",
                          width: "10vw",
                          height: "10vw",
                          top: "90%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          borderTop: "1px solid #ffffff00",
                          borderBottom: "1px solid white",
                          borderRight: "1px solid white",
                          borderLeft: "1px solid white",
                          borderRadius: "100px",
                          overflow: "hidden",
                          pointerEvents: "none",
                        }}
                      />

                      <Box
                        sx={{
                          position: "absolute",
                          width: "55%",
                          height: "125px",
                          top: "73%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          borderBottom: "1px solid white",
                          borderRight: "1px solid white",
                          borderLeft: "1px solid white",
                          pointerEvents: "none",
                          backgroundColor: "green",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "14%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "20%",
                          height: "50px",
                          borderBottom: "1px solid white",
                          borderRight: "1px solid white",
                          borderLeft: "1px solid white",
                          pointerEvents: "none",
                          backgroundColor: "green",
                        }}
                      />

                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Avatar
                          src={`https://media.api-sports.io/football/players/${
                            lineup[0].startXI.find(
                              (player) =>
                                player.player.grid === `${rowIndex + 1}:1`
                            ).player.id
                          }.png`}
                        />
                      </Box>
                      <Typography sx={{ position: "relative" }}>
                        {
                          lineup[0].startXI.find(
                            (player) =>
                              player.player.grid === `${rowIndex + 1}:1`
                          ).player.name
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <span></span>
                  )}
                </Grid>
              ) : (
                Array.from({ length: formation1[rowIndex - 1] }).map(
                  (_, columnIndex) => (
                    <Grid
                      key={columnIndex}
                      item
                      xs={12 / formation1[rowIndex - 1]}
                    >
                      {lineup[0].startXI.find(
                        (player) =>
                          player.player.grid ===
                          `${rowIndex + 1}:${columnIndex + 1}`
                      ) ? (
                        <Box>
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Avatar
                              src={`https://media.api-sports.io/football/players/${
                                lineup[0].startXI.find(
                                  (player) =>
                                    player.player.grid ===
                                    `${rowIndex + 1}:${columnIndex + 1}`
                                ).player.id
                              }.png`}
                            />
                          </Box>
                          <Typography>
                            {
                              lineup[0].startXI.find(
                                (player) =>
                                  player.player.grid ===
                                  `${rowIndex + 1}:${columnIndex + 1}`
                              ).player.name
                            }
                          </Typography>
                        </Box>
                      ) : (
                        <span></span>
                      )}
                    </Grid>
                  )
                )
              )}
            </Grid>
          ))}
          {/* <Divider /> */}
          {/* <Divider sx={{ position: "relative" }}> */}
          <Box sx={{ position: "relative" }}>
            <Divider />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "10vw",
                height: "10vw",
                borderRadius: "50%",
                border: "1px solid white", // White border
                pointerEvents: "none", // Ensures the circle doesn't interfere with pointer events
              }}
            />
          </Box>
          {/* </Divider> */}
          {/* 2nd Team */}
          {Array.from({ length: rows2 }).map((_, rowIndex) => (
            <Grid container key={rowIndex}>
              {rowIndex === rows2 - 1 ? (
                <Grid item xs={12}>
                  {lineup[1].startXI.find(
                    (player) => player.player.grid === "1:1"
                  ) ? (
                    <Box sx={{ position: "relative", mb: 2 }}>
                      <Box
                        sx={{
                          position: "absolute",
                          width: "10vw",
                          height: "10vw",
                          top: "0%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          borderTop: "1px solid white",
                          borderBottom: "1px solid #ffffff00",
                          borderRight: "1px solid white",
                          borderLeft: "1px solid white",
                          borderRadius: "100px",
                          overflow: "hidden",
                          pointerEvents: "none",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "27%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "55%",
                          height: "125px",
                          borderTop: "1px solid white",
                          borderRight: "1px solid white",
                          borderLeft: "1px solid white",
                          pointerEvents: "none",
                          backgroundColor: "green",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "86%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "20%",
                          height: "50px",
                          borderTop: "1px solid white",
                          borderRight: "1px solid white",
                          borderLeft: "1px solid white",
                          pointerEvents: "none",
                          backgroundColor: "green",
                        }}
                      />

                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Avatar
                          src={`https://media.api-sports.io/football/players/${
                            lineup[1].startXI.find(
                              (player) => player.player.grid === `1:1`
                            ).player.id
                          }.png`}
                        />
                      </Box>
                      <Typography sx={{ position: "relative" }}>
                        {
                          lineup[1].startXI.find(
                            (player) => player.player.grid === `1:1`
                          ).player.name
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <span></span>
                  )}
                </Grid>
              ) : (
                Array.from({ length: formation2[rowIndex] }).map(
                  (_, columnIndex) => (
                    <Grid key={columnIndex} item xs={12 / formation2[rowIndex]}>
                      {lineup[1].startXI.find(
                        (player) =>
                          player.player.grid ===
                          `${rows2 - rowIndex}:${columnIndex + 1}`
                      ) ? (
                        <Box>
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Avatar
                              src={`https://media.api-sports.io/football/players/${
                                lineup[1].startXI.find(
                                  (player) =>
                                    player.player.grid ===
                                    `${rows2 - rowIndex}:${columnIndex + 1}`
                                ).player.id
                              }.png`}
                            />
                          </Box>
                          {
                            lineup[1].startXI.find(
                              (player) =>
                                player.player.grid ===
                                `${rows2 - rowIndex}:${columnIndex + 1}`
                            ).player.name
                          }
                        </Box>
                      ) : (
                        <span></span>
                      )}
                    </Grid>
                  )
                )
              )}
            </Grid>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default LineupGrid;
