import { Box } from "@mui/material";
import LineupGrid from "./lineupGrid";

export const TeamLineup = ({ teamLineup }) => {
  return (
    <Box sx={{ backgroundColor: "green" }}>
      <LineupGrid lineup={teamLineup} />
    </Box>
  );
};
