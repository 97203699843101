import { ThemeProvider, createTheme } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";

const LocalThemeContext = React.createContext({ toggleColorMode: () => {} });

export function useColorMode() {
  return useContext(LocalThemeContext);
}

export function LocalTheme({ children }) {
  const [mode, setMode] = useState("dark");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: "#df622c",
          },
          secondary: {
            main: "#FFFFFF",
          },
        },
        typography: {
          fontFamily: "Comfortaa, cursive",
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                background: mode === "dark" ? "#17171d" : "#246eb93b",
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: "#062947",
              },
            },
          },
        },
      }),
    [mode]
  );

  return (
    <LocalThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </LocalThemeContext.Provider>
  );
}
