import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const apiEndpoint = "https://api-football-v1.p.rapidapi.com/v3";
const apiKey = "f7fd7543fdmsh26aabe510befe46p10bc93jsn29450c1d8ce6";

export default function TeamPage() {
  const [team, setTeam] = useState(null);

  const { season, leagueId, teamId } = useParams();

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const response = await fetch(
          `${apiEndpoint}/teams/statistics?league=${leagueId}&season=${season}&team=${teamId}`,
          {
            method: "GET",
            headers: {
              "x-rapidapi-host": "api-football-v1.p.rapidapi.com",
              "x-rapidapi-key": apiKey,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setTeam(data.response);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchTeam();
  }, [leagueId, season, teamId]);
  return team ? (
    <Stack spacing={2}>
      <Paper sx={{ p: 2, borderRadius: "10px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <img src={team.team.logo} alt="Team Logo" />
            <div>
              <Typography sx={{ textAlign: "left", mb: 2 }} variant="h3">
                {team.team.name}
              </Typography>
              <Stack direction="row" spacing={1}>
                {team.form.split("").map((stat, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      backgroundColor: stat === "W" ? "green" : "red",
                      borderRadius: "5px",
                      p: 1,
                    }}
                  >
                    {stat}
                  </Box>
                ))}
              </Stack>
            </div>
          </Box>
          <Box>
            <Box
              sx={{ display: "flex", mb: 2, justifyContent: "space-between" }}
            >
              <img
                src={team.league.flag}
                alt="League Logo"
                style={{ width: 30 }}
              />
              <Typography>{team.league.country}</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src={team.league.logo}
                alt="League Logo"
                style={{ width: 30 }}
              />
              <Typography sx={{ ml: 2 }}>{team.league.name}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Grid container>
        {Object.keys(team.fixtures).map((key, idx) => (
          <Grid key={idx} item xs={12 / Object.keys(team.fixtures).length}>
            <Paper
              sx={{
                p: 2,
                borderRadius: "10px",
                mr: idx !== Object.keys(team.fixtures).length - 1 && 2,
                backgroundColor:
                  key === "wins"
                    ? "#00800069"
                    : key === "loses"
                    ? "#ff000047"
                    : key === "played" && "#0000ff40",
              }}
            >
              <Typography>{key.toUpperCase()}</Typography>
              <Box sx={{ display: "flex", mt: 2 }}>
                <Paper
                  sx={{
                    p: 1,
                    backgroundColor: "#ff000000",
                    width: "100%",
                  }}
                >
                  <Typography>Home</Typography>
                  <Typography>{team.fixtures[key].home}</Typography>
                </Paper>
                <Paper
                  sx={{
                    p: 1,
                    backgroundColor: "#ff000000",
                    width: "100%",
                    mx: 2,
                  }}
                >
                  <Typography>Away</Typography>
                  <Typography>{team.fixtures[key].away}</Typography>
                </Paper>
                <Paper
                  sx={{ p: 1, backgroundColor: "#ff000000", width: "100%" }}
                >
                  <Typography>Total</Typography>
                  <Typography>{team.fixtures[key].total}</Typography>
                </Paper>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Paper
            sx={{
              borderRadius: "10px",
              p: 2,
              mr: 2,
              backgroundColor: "#0080002e",
            }}
          >
            <Typography>Goals Scored</Typography>
            <Box sx={{ display: "flex", mt: 2 }}>
              <Paper
                sx={{
                  p: 1,
                  backgroundColor: "#ff000000",
                  width: "100%",
                }}
              >
                <Typography>Home</Typography>
                <Typography>{team.goals.for.total.home}</Typography>
              </Paper>
              <Paper
                sx={{
                  p: 1,
                  backgroundColor: "#ff000000",
                  width: "100%",
                  mx: 2,
                }}
              >
                <Typography>Away</Typography>
                <Typography>{team.goals.for.total.away}</Typography>
              </Paper>
              <Paper sx={{ p: 1, backgroundColor: "#ff000000", width: "100%" }}>
                <Typography>Total</Typography>
                <Typography>{team.goals.for.total.total}</Typography>
              </Paper>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            sx={{ borderRadius: "10px", p: 2, backgroundColor: "#ff00001f" }}
          >
            <Typography>Goals Against</Typography>
            <Box sx={{ display: "flex", mt: 2 }}>
              <Paper
                sx={{
                  p: 1,
                  backgroundColor: "#ff000000",
                  width: "100%",
                }}
              >
                <Typography>Home</Typography>
                <Typography>{team.goals.against.total.home}</Typography>
              </Paper>
              <Paper
                sx={{
                  p: 1,
                  backgroundColor: "#ff000000",
                  width: "100%",
                  mx: 2,
                }}
              >
                <Typography>Away</Typography>
                <Typography>{team.goals.against.total.away}</Typography>
              </Paper>
              <Paper sx={{ p: 1, backgroundColor: "#ff000000", width: "100%" }}>
                <Typography>Total</Typography>
                <Typography>{team.goals.against.total.total}</Typography>
              </Paper>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Stack>
  ) : (
    <div></div>
  );
}
