import {
  Cached,
  SmartDisplay,
  SportsSoccer,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import yellowCard from "../../images/Yellow_card.png";
import { TeamLineup } from "./components/teamLineup";

const apiEndpoint = "https://api-football-v1.p.rapidapi.com/v3";
const apiKey = "f7fd7543fdmsh26aabe510befe46p10bc93jsn29450c1d8ce6";

const tabs = ["Info", "Events", "Stats", "Line-ups", "Table"];

const getEventIcon = (event) => {
  if (event.type.toLowerCase() === "goal") {
    return <SportsSoccer fontSize="small" />;
  } else if (event.type.toLowerCase() === "card") {
    return event.detail.toLowerCase() === "yellow card" ? (
      <Box
        component="img"
        sx={{
          height: 13,
          width: 9.5,
        }}
        alt="Yellow Card"
        src={yellowCard}
      />
    ) : (
      <img
        src={yellowCard}
        alt="Yellow Card"
        style={{ width: 20, height: 20 }}
      />
    );
  } else if (event.type.toLowerCase() === "subst") {
    return <Cached fontSize="small" />;
  } else {
    return <Warning />;
  }
};

export default function FixtureDetails() {
  const [fixture, setFixture] = useState(null);
  const [fixtureLineups, setFixtureLineups] = useState(null);
  const [selectedTab, setSelectedTab] = useState(1);
  const [league, setLeague] = useState(null);

  const { fixtureId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFixture = async () => {
      try {
        const userTimezone = dayjs.tz.guess();
        const response = await fetch(
          `${apiEndpoint}/fixtures?timezone=${userTimezone}&id=${fixtureId}`,
          {
            method: "GET",
            headers: {
              "x-rapidapi-host": "api-football-v1.p.rapidapi.com",
              "x-rapidapi-key": apiKey,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setFixture(data.response[0]);

        const resStandings = await fetch(
          `${apiEndpoint}/standings?league=${data.response[0].league.id}&season=${data.response[0].league.season}`,
          {
            method: "GET",
            headers: {
              "x-rapidapi-host": "api-football-v1.p.rapidapi.com",
              "x-rapidapi-key": apiKey,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const standingsData = await resStandings.json();
        setLeague(standingsData.response[0].league);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchFixture();
  }, [fixtureId]);

  useEffect(() => {
    if (fixture) {
      const fetchFixtureLineup = async () => {
        try {
          const response = await fetch(
            `${apiEndpoint}/fixtures/lineups?fixture=${fixture.fixture.id}`,
            {
              method: "GET",
              headers: {
                "x-rapidapi-host": "api-football-v1.p.rapidapi.com",
                "x-rapidapi-key": apiKey,
              },
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();
          setFixtureLineups(data.response);
        } catch (error) {
          console.error("Error fetching countries:", error);
        }
      };
      fetchFixtureLineup();
    }
  }, [fixture]);

  const renderTab = (fixture, selectedTab) => {
    switch (selectedTab) {
      case 0:
        break;
      case 1:
        return (
          <Box>
            {fixture.events.map((event, idx) => (
              <Grid container key={idx}>
                <Grid item xs={5} display="flex" alignItems="center">
                  {event.team.id === fixture.teams.home.id &&
                    (event.type !== "subst" ? (
                      <Paper
                        key={idx}
                        sx={{
                          backgroundColor: "#ffffff00",
                          display: "flex",
                          p: 2,
                          borderRadius: "10px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          height: "80%",
                        }}
                      >
                        <Typography variant="caption">
                          {event.player.name}
                        </Typography>
                        {getEventIcon(event)}
                      </Paper>
                    ) : (
                      <Paper
                        key={idx}
                        sx={{
                          backgroundColor: "#ffffff00",
                          display: "flex",
                          borderRadius: "10px",
                          px: 2,
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          height: "80%",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{ textAlign: "left" }}
                        >
                          <b style={{ color: "green" }}>
                            {event.assist.name} (IN)
                          </b>
                          <div style={{ color: "red" }}>
                            {event.player.name} (OUT)
                          </div>
                        </Typography>
                        {getEventIcon(event)}
                      </Paper>
                    ))}
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Divider orientation="vertical" sx={{ height: "13px" }} />
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", py: 1 }}
                  >
                    <Typography variant="caption">
                      {event.time.elapsed}'
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Divider orientation="vertical" sx={{ height: "13px" }} />
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  {event.team.id === fixture.teams.away.id &&
                    (event.type !== "subst" ? (
                      <Paper
                        key={idx}
                        sx={{
                          backgroundColor: "#ffffff00",
                          display: "flex",
                          p: 2,
                          borderRadius: "10px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          height: "80%",
                        }}
                      >
                        {getEventIcon(event)}
                        <Typography variant="caption">
                          {event.player.name}
                        </Typography>
                      </Paper>
                    ) : (
                      <Paper
                        key={idx}
                        sx={{
                          backgroundColor: "#ffffff00",
                          display: "flex",
                          borderRadius: "10px",
                          px: 2,
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          height: "80%",
                        }}
                      >
                        {getEventIcon(event)}
                        <Typography
                          variant="caption"
                          sx={{ textAlign: "right" }}
                        >
                          <b style={{ color: "green" }}>
                            {event.assist.name} (IN)
                          </b>
                          <div style={{ color: "red" }}>
                            {event.player.name} (OUT)
                          </div>
                        </Typography>
                      </Paper>
                    ))}
                </Grid>
              </Grid>
            ))}
          </Box>
        );
      case 2:
        break;
      case 3:
        break;
      case 4:
        return (
          <Box>
            {league ? (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Team</TableCell>
                      <TableCell>P</TableCell>
                      <TableCell>W</TableCell>
                      <TableCell>D</TableCell>
                      <TableCell>L</TableCell>
                      <TableCell>Goals</TableCell>
                      <TableCell>Last 5</TableCell>
                      <TableCell>Pts</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {league.standings[0].map((standing, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {standing.rank}
                        </TableCell>
                        <TableCell>
                          <CardActionArea
                            sx={{ borderRadius: "10px", p: 0.5 }}
                            onClick={() =>
                              navigate(
                                `/leagues/${fixture.league.season}/${fixture.league.id}/${standing.team.id}`
                              )
                            }
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                style={{
                                  width: 25,
                                  height: 25,
                                  marginRight: 5,
                                }}
                                src={standing.team.logo}
                                alt="Team"
                              />

                              {standing.team.name}
                            </Box>
                          </CardActionArea>
                        </TableCell>
                        <TableCell>{standing.all.played}</TableCell>
                        <TableCell>{standing.all.win}</TableCell>
                        <TableCell>{standing.all.draw}</TableCell>
                        <TableCell>{standing.all.lose}</TableCell>
                        <TableCell>{`${standing.all.goals.for}:${standing.all.goals.against}`}</TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                          >
                            {standing.form.split("").map((stat, index) => (
                              <Box
                                sx={{
                                  backgroundColor:
                                    stat === "W" ? "green" : "red",
                                  p: 1,
                                  borderRadius: "10px",
                                }}
                              >
                                <b>{stat}</b>
                              </Box>
                            ))}
                          </Stack>
                        </TableCell>
                        <TableCell>{standing.points}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        );

      default:
        break;
    }
  };

  return (
    <Paper sx={{ p: 2, borderRadius: "10px", backgroundColor: "#062947b8" }}>
      {fixture && (
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <img
                src={fixture.league.flag}
                alt="Country"
                style={{ width: "30px" }}
              />
              <Box sx={{ ml: 2, textAlign: "left" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {fixture.league.name}
                </Typography>
                <Typography variant="caption">
                  {fixture.league.country}
                </Typography>
              </Box>
            </Box>
            <IconButton>
              <SmartDisplay />
            </IconButton>
          </Box>
          <Paper
            sx={{
              p: 2,
              borderRadius: "10px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box>
                  <CardActionArea
                    sx={{ borderRadius: "10px", p: 1 }}
                    onClick={() =>
                      navigate(
                        `/leagues/${fixture.league.season}/${fixture.league.id}/${fixture.teams.home.id}`
                      )
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={fixture.teams.home.logo}
                        alt="Home"
                        style={{ width: 100 }}
                      />
                    </Box>
                    <Typography>{fixture.teams.home.name}</Typography>
                  </CardActionArea>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h3">
                  {fixture.goals.home} - {fixture.goals.away}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box>
                  <CardActionArea
                    sx={{ borderRadius: "10px", p: 1 }}
                    onClick={() =>
                      navigate(
                        `/leagues/${fixture.league.season}/${fixture.league.id}/${fixture.teams.away.id}`
                      )
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={fixture.teams.away.logo}
                        alt="Home"
                        style={{ width: 100 }}
                      />
                    </Box>
                    <Typography>{fixture.teams.away.name}</Typography>
                  </CardActionArea>
                </Box>
              </Grid>
            </Grid>
            <Typography>{fixture.fixture.status.long}</Typography>
          </Paper>
          <Grid container>
            <Grid item xs={6}>
              <Paper sx={{ p: 2, borderRadius: "10px", mr: 0.5 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-evenly"
                >
                  {tabs.map((tab, idx) => (
                    <Typography
                      onClick={() => setSelectedTab(idx)}
                      sx={{
                        cursor: "pointer",
                        color: selectedTab === idx && "#df622c",
                        fontWeight: selectedTab === idx && "bold",
                      }}
                      key={idx}
                    >
                      {tab}
                    </Typography>
                  ))}
                </Stack>
                <Divider sx={{ my: 2 }} />
                {renderTab(fixture, selectedTab)}
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ borderRadius: "10px", ml: 0.5 }}>
                {fixtureLineups && fixtureLineups.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      backgroundColor: "#146a17bf",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <Typography>{fixtureLineups[0].team.name}</Typography>
                    <Typography>{fixtureLineups[0].formation}</Typography>
                  </Box>
                )}
                {fixtureLineups && fixtureLineups.length > 0 && (
                  <TeamLineup teamLineup={fixtureLineups} />
                )}
                {fixtureLineups && fixtureLineups.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      backgroundColor: "#146a17bf",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <Typography>{fixtureLineups[1].team.name}</Typography>
                    <Typography>{fixtureLineups[1].formation}</Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Stack>
      )}
    </Paper>
  );
}
