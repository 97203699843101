import { CssBaseline } from "@mui/material";
import "./App.css";
import { LocalTheme } from "./contexts/ThemeContext";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import CustomNavBar from "./components/customNavBar";
import { AuthProvider } from "./contexts/authContext";
import { FirestoreProvider } from "./contexts/firestoreContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import LegaueDetailsPage from "./pages/leagues/legaueDetailsPage";
import PrivacyPolicyPage from "./pages/privacy/privacyPolicyPage";
import FixturesPage from "./pages/fixtures/fixturesPage";
import FixtureDetails from "./pages/fixtures/fixtureDetails";
import { AnalyticsProvider } from "./contexts/analyticsContext";
import TeamPage from "./pages/teams/teamPage";

function App() {
  return (
    <div className="App">
      <LocalTheme>
        <CssBaseline />
        <Router>
          <AuthProvider>
            <AnalyticsProvider>
              <FirestoreProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomNavBar>
                    <Routes>
                      <Route path="/" element={<Navigate to="/fixtures" />} />

                      <Route path="/fixtures" element={<FixturesPage />} />
                      <Route
                        path="/fixtures/:fixtureId"
                        element={<FixtureDetails />}
                      />
                      <Route
                        path="/leagues/:season/:leagueId/:teamId"
                        element={<TeamPage />}
                      />
                      <Route
                        path="/leagues/:season/:leagueId"
                        element={<LegaueDetailsPage />}
                      />

                      <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicyPage />}
                      />
                    </Routes>
                  </CustomNavBar>
                </LocalizationProvider>
              </FirestoreProvider>
            </AnalyticsProvider>
          </AuthProvider>
        </Router>
      </LocalTheme>
    </div>
  );
}

export default App;
