import {
  Cached,
  ExpandLess,
  ExpandMore,
  Search,
  SportsSoccer,
  Star,
  Subscriptions,
  Warning,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CardActionArea,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import yellowCard from "../../images/Yellow_card.png";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "../../contexts/firestoreContext";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
dayjs.extend(utc);
dayjs.extend(timezone);

const normalise = (value) => ((value - 0) * 100) / (90 - 0);

const getEventIcon = (event) => {
  if (event.type.toLowerCase() === "goal") {
    return <SportsSoccer fontSize="small" />;
  } else if (event.type.toLowerCase() === "card") {
    return event.detail.toLowerCase() === "yellow card" ? (
      <Box
        component="img"
        sx={{
          height: 13,
          width: 9.5,
        }}
        alt="Yellow Card"
        src={yellowCard}
      />
    ) : (
      <img
        src={yellowCard}
        alt="Yellow Card"
        style={{ width: 20, height: 20 }}
      />
    );
  } else if (event.type.toLowerCase() === "subst") {
    return <Cached fontSize="small" />;
  } else {
    return <Warning />;
  }
};

const youtubeVideoId1 = "5VcsE86D-MI";
// const youtubeVideoId2 = "DTreQ8oKYRM";
const embedUrl = `https://www.youtube.com/embed/`;

const handleSubscribe = () => {
  logEvent(analytics, "select_content", {
    content_type: "video",
    content_id: "ChaiTimeTactics",
  });
  window.open(
    "https://www.youtube.com/@ChaiTimeTactics?sub_confirmation=1",
    "_blank"
  );
};

export default function FixturesPage() {
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [searchCountryText, setSearchCountryText] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [openCountries, setOpenCountries] = useState([]);

  const [leagues, setLeagues] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const navigate = useNavigate();
  const {
    firestoreUser,
    updateUser,
    getAllCountries,
    getLeaguesByCountry,
    listenToNextFixtures,
    nextFixtures,
    listenToLastFixtures,
    lastFixtures,
    listenToLiveFixtures,
    liveScores,
  } = useFirestore();

  useEffect(() => {
    const fetchCountries = async () => {
      const countries = await getAllCountries();
      setCountries(countries);
    };
    const unsubNext = listenToNextFixtures();
    const unsubLast = listenToLastFixtures();
    const unsubLive = listenToLiveFixtures();

    fetchCountries();
    setLoading(false);

    return () => {
      unsubNext();
      unsubLast();
      unsubLive();
    };
    // eslint-disable-next-line
  }, []);

  const debouncedFilterCountries = debounce(
    (text, countries) => {
      setFilteredCountries(
        countries.filter((country) =>
          country.name.toLowerCase().includes(text.toLowerCase())
        )
      );
    },
    300 // 300 milliseconds debounce time
  );

  useEffect(() => {
    debouncedFilterCountries(searchCountryText, countries);
    // eslint-disable-next-line
  }, [searchCountryText, countries]);

  const handleCountryClick = async (country) => {
    if (!leagues[country.name]) {
      const leagues = await getLeaguesByCountry(country.name);
      setLeagues((prevLeagues) => ({
        ...prevLeagues,
        [country.name]: leagues,
      }));
    }

    // Toggle the open state for the country
    setOpenCountries((prevOpenCountries) => ({
      ...prevOpenCountries,
      [country.name]: !prevOpenCountries[country.name],
    }));
  };

  const handleLeagueClick = async (league) => {
    const season = league.seasons[league.seasons.length - 1].year;
    const leagueId = league.league.id;
    navigate(`/leagues/${season}/${leagueId}`);
  };

  const handleFLeagueToggle = async (league) => {
    const isFav =
      firestoreUser.fLeagues && firestoreUser.fLeagues[league.league.id];
    if (isFav) {
      // Remove the league id from the fLeagues array
      const updatedFLeagues = { ...firestoreUser.fLeagues };
      delete updatedFLeagues[league.league.id];
      await updateUser({ fLeagues: updatedFLeagues });
    } else {
      // Add the league id to the fLeagues array
      await updateUser({ [`fLeagues.${league.league.id}`]: league });
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {/* List of All Countries and Leagues */}
          <Grid item xs={12} sm={2}>
            <Stack spacing={2}>
              <DateCalendar
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
              />

              <Paper sx={{ p: 2 }}>
                <iframe
                  width="250"
                  height="150"
                  src={embedUrl + youtubeVideoId1}
                  title="Chai Time Tactics Video 1"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  color="error"
                  startIcon={<Subscriptions />}
                  onClick={handleSubscribe}
                >
                  Subscribe
                </Button>
              </Paper>

              <Paper
                sx={{
                  borderRadius: "10px",
                  p: 2,
                  textAlign: "left",
                }}
              >
                <Typography>All Leagues</Typography>
                <TextField
                  sx={{ my: 2 }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={searchCountryText}
                  onChange={(e) => setSearchCountryText(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
                <List>
                  {filteredCountries.map((country, idx) => (
                    <React.Fragment key={idx}>
                      <ListItem
                        disablePadding
                        onClick={() => handleCountryClick(country)}
                        sx={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          backgroundColor: openCountries[country.name]
                            ? "#062947"
                            : "inherit",
                        }}
                      >
                        <ListItemButton sx={{ borderRadius: "10px" }}>
                          <ListItemIcon>
                            <Avatar
                              src={country.flag}
                              sx={{ width: 24, height: 24 }}
                            />
                          </ListItemIcon>

                          <ListItemText primary={country.name} />
                          {openCountries[country.name] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                      </ListItem>
                      <Collapse
                        in={openCountries[country.name]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {leagues[country.name] &&
                            leagues[country.name].map((league, idx) => (
                              <ListItem
                                key={idx}
                                sx={{
                                  // pl: 4,
                                  backgroundColor: "#062947",
                                }}
                                disablePadding
                                secondaryAction={
                                  firestoreUser && (
                                    <IconButton
                                      edge="end"
                                      aria-label="favourites"
                                      onClick={() =>
                                        handleFLeagueToggle(league)
                                      }
                                    >
                                      <Star
                                        fontSize="small"
                                        color={
                                          firestoreUser.fLeagues &&
                                          firestoreUser.fLeagues[
                                            league.league.id
                                          ]
                                            ? "error"
                                            : ""
                                        }
                                      />
                                    </IconButton>
                                  )
                                }
                              >
                                <ListItemButton
                                  sx={{ borderRadius: "10px" }}
                                  onClick={() => handleLeagueClick(league)}
                                >
                                  <ListItemIcon>
                                    <Avatar
                                      src={league.league.logo}
                                      sx={{ width: 24, height: 24 }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={league.league.name} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
            </Stack>
          </Grid>

          {/* Upcoming Matches */}
          <Grid item xs={12} sm={3}>
            <Paper sx={{ p: 2 }}>
              <Typography sx={{ mb: 2 }}>Upcoming Matches</Typography>
              <Stack spacing={2}>
                {nextFixtures.map((fixture, idx) => (
                  <CardActionArea
                    key={idx}
                    sx={{ borderRadius: "10px" }}
                    onClick={() => navigate(`${fixture.fixture.id}`)}
                  >
                    <Paper sx={{ p: 1, backgroundColor: "#2cb6df1c" }}>
                      <Box sx={{ display: "flex", mb: 1 }}>
                        <Avatar
                          src={fixture.league.flag || fixture.league.logo}
                          sx={{ mr: 2 }}
                        />
                        <Box sx={{ textAlign: "left" }}>
                          <Typography variant="body2">
                            {fixture.league.name}
                          </Typography>
                          <Typography variant="caption">
                            {fixture.league.country}
                          </Typography>
                        </Box>
                      </Box>
                      <Paper
                        sx={{
                          p: 0.5,
                          backgroundColor: "#06294761",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={3}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography variant="caption" sx={{ mr: 2 }}>
                              {dayjs
                                .unix(fixture.fixture.timestamp)
                                .format("h:mm A")}
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Box sx={{ display: "flex" }}>
                              <Avatar
                                sx={{ width: 20, height: 20, mr: 2 }}
                                src={fixture.teams.home.logo}
                              />
                              <Typography>{fixture.teams.home.name}</Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <Avatar
                                sx={{ width: 20, height: 20, mr: 2 }}
                                src={fixture.teams.away.logo}
                              />
                              <Typography>{fixture.teams.away.name}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Paper>
                  </CardActionArea>
                ))}
              </Stack>
            </Paper>
          </Grid>

          {/* Live Scores */}
          <Grid item xs={12} sm={4}>
            <Paper sx={{ p: 2 }}>
              <Typography sx={{ mb: 2 }}>Live Scores</Typography>
              {liveScores.length === 0 ? (
                <Box
                  sx={{
                    minHeight: "250px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <SportsSoccer sx={{ width: 100, height: 100 }} />
                    <Typography>No match is being played right now.</Typography>
                  </div>
                </Box>
              ) : (
                <Stack spacing={2}>
                  {liveScores.map((fixture, idx) => (
                    <CardActionArea
                      key={idx}
                      sx={{ borderRadius: "10px" }}
                      onClick={() => navigate(`${fixture.fixture.id}`)}
                    >
                      <Paper
                        sx={{
                          p: 1,
                          backgroundColor:
                            fixture.fixture.status.short === "LIVE"
                              ? "#ff00001c"
                              : "#2cb6df1c",
                        }}
                      >
                        <Box sx={{ display: "flex", mb: 1 }}>
                          <Avatar
                            src={fixture.league.flag || fixture.league.logo}
                            sx={{ mr: 2 }}
                          />
                          <Box sx={{ textAlign: "left" }}>
                            <Typography variant="caption">
                              {fixture.league.country}
                            </Typography>
                            <Typography variant="body2">
                              {fixture.league.name}
                            </Typography>
                          </Box>
                        </Box>
                        <Typography
                          variant="caption"
                          className={
                            fixture.fixture.status.short === "1H" ||
                            fixture.fixture.status.short === "2H"
                              ? "blinking"
                              : ""
                          }
                          sx={{
                            color:
                              fixture.fixture.status.short === "1H" ||
                              fixture.fixture.status.short === "2H"
                                ? "red"
                                : "inherit",
                            fontWeight:
                              fixture.fixture.status.short === "1H" ||
                              fixture.fixture.status.short === "2H"
                                ? "bold"
                                : "normal",
                          }}
                        >
                          {fixture.fixture.status.short === "1H" ||
                          fixture.fixture.status.short === "2H"
                            ? `LIVE - ${fixture.fixture.status.elapsed}'`
                            : fixture.fixture.status.long}
                        </Typography>
                        <Paper sx={{ p: 2 }}>
                          <Grid container spacing={2}>
                            {/* Home Team & Score */}
                            <Grid
                              item
                              xs={4}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Box sx={{ display: "flex" }}>
                                <Avatar
                                  sx={{ width: 20, height: 20, mr: 2 }}
                                  src={fixture.teams.home.logo}
                                />
                                <Typography>
                                  {fixture.teams.home.name}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography color="red">
                                {fixture.goals.home}
                              </Typography>
                            </Grid>

                            {/* Divider */}
                            <Grid
                              item
                              xs={2}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography variant="caption">-</Typography>
                            </Grid>

                            {/* Away Team & Score */}
                            <Grid
                              item
                              xs={1}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography color="red">
                                {fixture.goals.away}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Box sx={{ display: "flex" }}>
                                <Avatar
                                  sx={{ width: 20, height: 20, mr: 2 }}
                                  src={fixture.teams.away.logo}
                                />
                                <Typography>
                                  {fixture.teams.away.name}
                                </Typography>
                              </Box>
                            </Grid>

                            {/* Progress Bar */}
                            <Grid item xs={0.5}></Grid>
                            <Grid item xs={11}>
                              <LinearProgress
                                variant="determinate"
                                value={normalise(
                                  fixture.fixture.status.elapsed
                                )}
                              />
                            </Grid>
                            <Grid item xs={0.5}></Grid>

                            {/* Events */}
                            <Grid item xs={5}>
                              <Stack spacing={1}>
                                {fixture.events
                                  .filter(
                                    (event) =>
                                      event.team.id === fixture.teams.home.id
                                  )
                                  .map((event, idx) =>
                                    event.type !== "subst" ? (
                                      <Paper
                                        key={idx}
                                        sx={{
                                          backgroundColor: "#ffffff00",
                                          display: "flex",
                                          p: 1,
                                          borderRadius: "10px",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography variant="caption">
                                          {event.time.elapsed}'
                                        </Typography>
                                        <Typography variant="caption">
                                          {event.player.name}
                                        </Typography>
                                        {getEventIcon(event)}
                                      </Paper>
                                    ) : (
                                      <Paper
                                        key={idx}
                                        sx={{
                                          backgroundColor: "#ffffff00",
                                          display: "flex",
                                          borderRadius: "10px",
                                          px: 1,
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography variant="caption">
                                          {event.time.elapsed}'
                                        </Typography>
                                        <Typography variant="caption">
                                          <b style={{ color: "green" }}>
                                            {event.player.name} (IN)
                                          </b>
                                          <div style={{ color: "red" }}>
                                            {event.assist.name} (OUT)
                                          </div>
                                        </Typography>
                                        {getEventIcon(event)}
                                      </Paper>
                                    )
                                  )}
                              </Stack>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={5}>
                              <Stack spacing={1}>
                                {fixture.events
                                  .filter(
                                    (event) =>
                                      event.team.id === fixture.teams.away.id
                                  )
                                  .map((event, idx) =>
                                    event.type !== "subst" ? (
                                      <Paper
                                        key={idx}
                                        sx={{
                                          backgroundColor: "#ffffff00",
                                          display: "flex",
                                          p: 1,
                                          borderRadius: "10px",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography variant="caption">
                                          {event.time.elapsed}'
                                        </Typography>
                                        <Typography variant="caption">
                                          {event.player.name}
                                        </Typography>
                                        {getEventIcon(event)}
                                      </Paper>
                                    ) : (
                                      <Paper
                                        key={idx}
                                        sx={{
                                          backgroundColor: "#ffffff00",
                                          display: "flex",
                                          borderRadius: "10px",
                                          px: 1,
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography variant="caption">
                                          {event.time.elapsed}'
                                        </Typography>
                                        <Typography variant="caption">
                                          <b style={{ color: "green" }}>
                                            {event.player.name} (IN)
                                          </b>
                                          <div style={{ color: "red" }}>
                                            {event.assist.name} (OUT)
                                          </div>
                                        </Typography>
                                        {getEventIcon(event)}
                                      </Paper>
                                    )
                                  )}
                              </Stack>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Paper>
                    </CardActionArea>
                  ))}
                </Stack>
              )}
            </Paper>
          </Grid>

          {/* Finished Matches & Favourites */}
          <Grid item xs={12} sm={3}>
            <Stack spacing={2}>
              <Paper sx={{ p: 2 }}>
                <Typography sx={{ mb: 2 }}>Finished Matches</Typography>
                <Stack spacing={2}>
                  {lastFixtures.map((fixture, idx) => (
                    <CardActionArea
                      key={idx}
                      onClick={() => navigate(`${fixture.fixture.id}`)}
                    >
                      <Paper sx={{ p: 1, backgroundColor: "#2cb6df1c" }}>
                        <Box sx={{ display: "flex", mb: 1 }}>
                          <Avatar
                            src={fixture.league.flag || fixture.league.logo}
                            sx={{ mr: 2 }}
                          />
                          <Box sx={{ textAlign: "left", mr: 2 }}>
                            <Typography variant="body2">
                              {fixture.league.name}
                            </Typography>
                            <Typography variant="caption">
                              {fixture.league.country}
                            </Typography>
                          </Box>
                          <Typography variant="body2">
                            {dayjs
                              .unix(fixture.fixture.timestamp)
                              .format("h:mm A - D/M/YYYY ")}
                          </Typography>
                        </Box>

                        <Paper sx={{ p: 2 }}>
                          <Grid container spacing={2}>
                            {/* Home Team & Score */}
                            <Grid
                              item
                              xs={4}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Box sx={{ display: "flex" }}>
                                <Avatar
                                  sx={{ width: 20, height: 20, mr: 2 }}
                                  src={fixture.teams.home.logo}
                                />
                                <Typography>
                                  {fixture.teams.home.name}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography color="red">
                                {fixture.goals.home}
                              </Typography>
                            </Grid>

                            {/* Divider */}
                            <Grid
                              item
                              xs={2}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography variant="caption">-</Typography>
                            </Grid>

                            {/* Away Team & Score */}
                            <Grid
                              item
                              xs={1}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography color="red">
                                {fixture.goals.away}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Box sx={{ display: "flex" }}>
                                <Avatar
                                  sx={{ width: 20, height: 20, mr: 2 }}
                                  src={fixture.teams.away.logo}
                                />
                                <Typography>
                                  {fixture.teams.away.name}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Paper>
                    </CardActionArea>
                  ))}
                </Stack>
              </Paper>
              {firestoreUser && (
                <Paper sx={{ p: 2 }}>
                  <Typography sx={{ mb: 2 }}>Favourites</Typography>
                  <List component="div" disablePadding>
                    {Object.keys(firestoreUser.fLeagues).map((leagueId) => {
                      const league = firestoreUser.fLeagues[leagueId];
                      return (
                        <ListItem
                          key={leagueId}
                          secondaryAction={
                            firestoreUser && (
                              <IconButton
                                edge="end"
                                aria-label="favourites"
                                onClick={() => handleFLeagueToggle(league)}
                              >
                                <Star
                                  fontSize="small"
                                  color={
                                    firestoreUser.fLeagues &&
                                    firestoreUser.fLeagues[league.league.id]
                                      ? "error"
                                      : ""
                                  }
                                />
                              </IconButton>
                            )
                          }
                        >
                          <ListItemButton
                            onClick={() => handleLeagueClick(league)}
                            sx={{
                              borderRadius: "10px",
                              backgroundColor: "#ffffff00",
                            }}
                          >
                            <ListItemIcon>
                              <Avatar
                                src={league.league.logo}
                                sx={{ width: 24, height: 24 }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={league.league.name} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              )}
            </Stack>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
