import {
  Avatar,
  Box,
  CardMedia,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function LegaueDetailsPage() {
  const [league, setLeague] = useState(null);
  const { leagueId, season } = useParams();

  const apiEndpoint = "https://api-football-v1.p.rapidapi.com/v3";
  const apiKey = "f7fd7543fdmsh26aabe510befe46p10bc93jsn29450c1d8ce6";

  useEffect(() => {
    const fetchLeagueDetails = async () => {
      try {
        const response = await fetch(
          `${apiEndpoint}/standings?league=${leagueId}&season=${season}`,
          {
            method: "GET",
            headers: {
              "x-rapidapi-host": "api-football-v1.p.rapidapi.com",
              "x-rapidapi-key": apiKey,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setLeague(data.response[0].league);
      } catch (error) {
        console.error(`Error fetching leagues for ${leagueId}:`, error);
      }
    };
    fetchLeagueDetails();
  }, [leagueId, season]);

  return (
    <Box>
      {league ? (
        <Stack spacing={2}>
          <Paper sx={{ p: 2 }}>
            <Grid container>
              <Grid item xs={4}>
                <CardMedia
                  sx={{ height: 150, backgroundSize: "contain" }}
                  image={league.logo}
                />
              </Grid>
              <Grid item xs={8} sx={{ textAlign: "left" }}>
                <Typography variant="h5">{league.name}</Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar sx={{ mr: 2 }} src={league.flag} />
                  <Typography>{league.country.toUpperCase()}</Typography>
                </div>
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={{ p: 2 }}>
            <Typography>Standings</Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Team</TableCell>
                    <TableCell>P</TableCell>
                    <TableCell>W</TableCell>
                    <TableCell>D</TableCell>
                    <TableCell>L</TableCell>
                    <TableCell>Goals</TableCell>
                    <TableCell>Last 5</TableCell>
                    <TableCell>Pts</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {league.standings[0].map((standing, idx) => (
                    <TableRow
                      key={idx}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {standing.rank}
                      </TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item xs={2}>
                            <CardMedia
                              sx={{
                                height: 25,
                                backgroundSize: "contain",
                              }}
                              image={standing.team.logo}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {standing.team.name}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>{standing.all.played}</TableCell>
                      <TableCell>{standing.all.win}</TableCell>
                      <TableCell>{standing.all.draw}</TableCell>
                      <TableCell>{standing.all.lose}</TableCell>
                      <TableCell>{`${standing.all.goals.for}:${standing.all.goals.against}`}</TableCell>
                      <TableCell>{standing.form}</TableCell>
                      <TableCell>{standing.points}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Stack>
      ) : (
        <Box
          sx={{
            display: "flex",
            // height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
